<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="기본정보">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-show="editable&&!isOld"
                label="타 목표 불러오기"
                icon="save_alt"
                @btnClicked="copyTarget"
              />
              <c-btn 
                v-if="editable&&isOld" 
                label="삭제" 
                icon="delete"
                @btnClicked="deleteTarget" />
              <c-btn 
                v-if="editable" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="target"
                :mappingType="mappingType"
                label="저장" 
                icon="save"
                @beforeAction="saveTarget"
                @btnCallback="saveTargetCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                :required="true"
                :editable="editable"
                name="plantCd"
                v-model="target.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-select
                :required="true"
                codeGroupCd="MAIN_SAFETY_HEALTHY_TYPE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="safetyHealthyTypeCd"
                label="구분"
                v-model="target.safetyHealthyTypeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :required="true"
                default="today"
                label="제목"
                name="targetTitle"
                v-model="target.targetTitle"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :required="true"
                :editable="editable"
                type="year"
                default="today"
                label="대상년도"
                name="targetYear"
                v-model="target.targetYear"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-dept 
                :required="true" 
                :editable="editable" 
                type="edit" 
                label="작성부서" 
                name="writeDeptCd" 
                v-model="target.writeDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :required="true"
                default="today"
                label="작성일"
                name="writeDt"
                v-model="target.writeDt"
              />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="관계법령 의무사항 이행 계획/실적"
          :merge="grid.merge"
          :columns="grid.columns"
          :data="target.plans"
          :gridHeight="gridHeight"
          :editable="editable"
          :usePaging="false"
          :isExcelDown="true"
          :filtering="false"
          :isFullScreen="true"
          :columnSetting="false"
          selection="multiple"
          rowKey="mainSafetyHealthyTargetPlanId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addTargetPlan"/>
              <c-btn v-if="editable&&target.plans&&target.plans.length>0" label="제외" icon="remove" @btnClicked="deleteTargetPlan" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <!-- <template v-if="col.name === 'targetName'">
              {{ props.row.targetName }}
              <div v-if="editable">
                <q-btn-group outline class="ColumInnerBtnGroup">
                  <q-btn
                    icon="add"
                    color="red-6"
                    text-color="white"
                    class="ColumInnerBtn"
                    align="center"
                    @click.prevent="innerBtnClicked(col, props)">
                    <q-tooltip>
                      {{'의무사항 목표별 세부추진사항 추가'}}
                    </q-tooltip>
                  </q-btn>
                </q-btn-group>
              </div>
            </template> -->
            <template v-if="col.name === 'custom' && props.row.editFlag !== 'C'">
              <!-- 
                1. 실적이 들어가지 않은경우
                - 입력하기
                2. 실적이 들어간 경우
                - ㅁ건 
              -->
              <template v-if="props.row.results && props.row.results.length > 0">
                <q-btn 
                  dense
                  outline 
                  color="blue-9"
                  style="font-weight:700"
                  :label="`${props.row.results.length} ${$language('건')}`" 
                  @click="openResult(props.row)" />
              </template>
              <template v-else>
                <q-btn 
                  dense
                  outline  
                  color="green-9"
                  style="font-weight:700"
                  :label="$language('입력하기')"
                  @click="openResult(props.row)" />
              </template>
            </template>
          </template>
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'safety-healthy-target-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mainSafetyHealthyTargetId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      target: {
        mainSafetyHealthyTargetId: '',  // 안전보건목표 일련번호
        plantCd: null,  // 사업장
        propelDeptName: '',  // 추진부서
        safetyHealthyTypeCd: null,
        targetYear: '',  // 대상년도
        targetTitle: '', // 제목
        writeDeptCd: '',  // 작성부서
        writeDt: '',  // 작성일
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        plans: [],
        deletePlans: [],
      },
      grid: {
        merge: [
          { index: 0, colName: "mainSafetyHealthyId" },
        ],
        columns: [
          {
            name: 'targetName',
            field: 'targetName',
            label: '목표',
            align: 'left',
            // type: 'custom',
            style: 'width: 200px',
            sortable: false,
            fix: true,
          },
          // {
          //   name: 'deptName',
          //   field: 'deptName',
          //   label: '해당부서',
          //   align: 'left',
          //   style: 'width: 100px',
          //   sortable: false,
          //   fix: true,
          // },
          {
            name: 'detailName',
            field: 'detailName',
            label: '세부추진사항',
            align: 'left',
            type: 'textarea',
            style: 'width: 180px',
            sortable: false,
            fix: true,
          },
          // {
          //   name: 'kpi',
          //   field: 'kpi',
          //   label: '준수지표',
          //   align: 'left',
          //   style: 'width: 120px',
          //   type: 'textarea',
          //   sortable: false,
          //   fix: true,
          // },
          {
            name: 'schedule',
            field: 'schedule',
            label: '추진일정',
            child: [
              {
                name: 'month1',
                field: 'month1',
                label: '1월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month2',
                field: 'month2',
                label: '2월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month3',
                field: 'month3',
                label: '3월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month4',
                field: 'month4',
                label: '4월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month5',
                field: 'month5',
                label: '5월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month6',
                field: 'month6',
                label: '6월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month7',
                field: 'month7',
                label: '7월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month8',
                field: 'month8',
                label: '8월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month9',
                field: 'month9',
                label: '9월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month10',
                field: 'month10',
                label: '10월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month11',
                field: 'month11',
                label: '11월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month12',
                field: 'month12',
                label: '12월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
            ]
          },
          {
            name: 'result',
            field: 'result',
            label: '성과',
            child: [
              {
                name: 'status',
                field: 'status',
                label: '현황',
                align: 'center',
                style: 'width: 100px',
                setHeader: true,
                sortable: false,
                isChip: true,
                codeGroupCd: 'SAFETY_HEALTH_STATUS_CD',
                type: 'select',
              },
              {
                name: 'achievementRate',
                field: 'achievementRate',
                label: '달성률(%)',
                align: 'center',
                style: 'width: 80px',
                sortable: false,
                type: 'number',
              },
            ],
          },
          {
            name: 'budget',
            field: 'budget',
            label: '예산(만원)',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
            type: 'number',
          },
          {
            name: 'performanceDetails',
            field: 'performanceDetails',
            label: '실적내용',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
            type: 'textarea',
          },
          {
            name: 'custom',
            field: 'custom',
            label: '상세실적',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
            type: 'textarea',
          },
        ],
        data: [],
      },
      planRow: null,
      editable: false,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '50px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 180) + 'px';
    },
    isOld() {
      return Boolean(this.popupParam.mainSafetyHealthyTargetId)
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.mainsafetyHealthy.get.url;
      this.saveUrl = transactionConfig.sai.mainsafetyHealthy.insert.url;
      this.deleteUrl = transactionConfig.sai.mainsafetyHealthy.delete.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.mainSafetyHealthyTargetId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$_.extend(this.target, _result.data)
        },);
      } else {
        // 신규등록 
        this.$set(this.target, 'writeDeptCd', this.$store.getters.user.deptCd)
        this.$set(this.target, 'writeDt', this.$comm.getToday())
      }
    },
    addTargetPlan() {
      this.popupOptions.title = '안전보건 목표 검색';  
      this.popupOptions.target = () => import(`${'./mainSafetyHealthyMstPopup.vue'}`);
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTargetPopup;
    },
    closeTargetPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.target.plans, { mainSafetyHealthyId: item.mainSafetyHealthyId }) === -1) {
            this.target.plans.push({
              mainSafetyHealthyTargetPlanId: uid(),  // 안전보건목표 계획 일련번호
              mainSafetyHealthyTargetId: this.popupParam.mainSafetyHealthyTargetId,  // 안전보건목표 일련번호
              mainSafetyHealthyId: item.mainSafetyHealthyId,  // 안전보건목표 마스터 일련번호
              targetName: item.targetName,  // 안전보건목표
              detailName: '',  // 세부추진사항
              deptName: item.deptName,  // 해당부서
              kpi: '',  // kpi 성과지표
              performanceGoal: '',  // 성과목표
              status: null,  // 현황
              achievementRate: 0,  // 달성율
              budget: 0,  // 예산
              performanceDetails: '',  // 실적내용
              remark: '',  // 비고
              month1: 'N',  // 1월 계획
              month2: 'N',  // 2월 계획
              month3: 'N',  // 3월 계획
              month4: 'N',  // 4월 계획
              month5: 'N',  // 5월 계획
              month6: 'N',  // 6월 계획
              month7: 'N',  // 7월 계획
              month8: 'N',  // 8월 계획
              month9: 'N',  // 9월 계획
              month10: 'N',  // 10월 계획
              month11: 'N',  // 11월 계획
              month12: 'N',  // 12월 계획
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C'
            })
          }
        })
      }
    },
    innerBtnClicked(col, props) {
      this.target.plans.splice(props.rowIndex + 1, 0, {
        mainSafetyHealthyTargetPlanId: uid(),  // 안전보건목표 계획 일련번호
        mainSafetyHealthyTargetId: this.popupParam.mainSafetyHealthyTargetId,  // 안전보건목표 일련번호
        mainSafetyHealthyId: props.row.mainSafetyHealthyId,  // 안전보건목표 마스터 일련번호
        targetName: props.row.targetName,  // 안전보건목표
        detailName: '',  // 세부추진사항
        deptName: '',  // 해당부서
        kpi: '',  // kpi 성과지표
        performanceGoal: '',  // 성과목표
        status: null,  // 현황
        achievementRate: 0,  // 달성율
        budget: 0,  // 예산
        performanceDetails: '',  // 실적내용
        remark: '',  // 비고
        month1: 'N',  // 1월 계획
        month2: 'N',  // 2월 계획
        month3: 'N',  // 3월 계획
        month4: 'N',  // 4월 계획
        month5: 'N',  // 5월 계획
        month6: 'N',  // 6월 계획
        month7: 'N',  // 7월 계획
        month8: 'N',  // 8월 계획
        month9: 'N',  // 9월 계획
        month10: 'N',  // 10월 계획
        month11: 'N',  // 11월 계획
        month12: 'N',  // 12월 계획
        regUserId: this.$store.getters.user.userId,  // 등록자
        editFlag: 'C'
      })
    },
    deleteTargetPlan() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',  
          message: '제외하시겠습니까?', 
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.target.deletePlans) this.target.deletePlans = [];
            this.$_.forEach(selectData, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.target.deletePlans, { mainSafetyHealthyTargetPlanId: item.mainSafetyHealthyTargetPlanId })) {
                  this.target.deletePlans.push(item)
              }
              this.target.plans = this.$_.reject(this.target.plans, item);
            })
            this.$refs['table'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openResult(row) {
      if (!row) return;
      this.$set(this.$data, 'planRow', row)
      this.popupOptions.title = row.targetName + ' ' + this.$language('상세')
      this.popupOptions.param = {
        mainSafetyHealthyTargetId: row.mainSafetyHealthyTargetId,
        mainSafetyHealthyTargetPlanId: row.mainSafetyHealthyTargetPlanId,
      };
      this.popupOptions.target = () => import(`${'./mainSafetyHealthyTargetDetailPopup.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    closeResultPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (result && result.col1) {
        this.$set(this.planRow, 'results', result.col1)
      }
    },
    saveTarget() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sai.mainsafetyHealthy.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sai.mainsafetyHealthy.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: '저장하시겠습니까?',  
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.target.regUserId = this.$store.getters.user.userId
              this.target.chgUserId = this.$store.getters.user.userId
      
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveTargetCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.mainSafetyHealthyTargetId = result.data
      this.getDetail();
    },
    deleteTarget() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?', 
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.mainSafetyHealthyTargetId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    copyTarget() {
      this.popupOptions.title = "타 ESF 목표 및 추진계획/실적 검색"; 
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () => import(`${"./mainSafetyHealthyTargetPopup.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyTargetPopup;
    },
    closeCopyTargetPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$http.url = this.$format(this.detailUrl, data[0].mainSafetyHealthyTargetId);
        this.$http.type = "GET";
        this.$http.request((_result) => {
          this.target.plantCd = _result.data.plantCd
          this.target.targetYear = this.$comm.getThisYear();
          this.target.writeDeptCd = _result.data.writeDeptCd
          this.target.writeDt = this.$comm.getToday();
          this.target.regUserId = this.$store.getters.user.userId

          this.$_.forEach(_result.data.plans, item => {
            let _idx = this.$_.findIndex(this.target.plans, { mainSafetyHealthyId: item.mainSafetyHealthyId })
            _idx = _idx > -1 ? _idx : 0;
            this.target.plans.splice(_idx, 0, {
              mainSafetyHealthyTargetPlanId: uid(),  // 안전보건목표 계획 일련번호
              mainSafetyHealthyTargetId: this.popupParam.mainSafetyHealthyTargetId,  // 안전보건목표 일련번호
              mainSafetyHealthyId: item.saiSafetyHealthyId,  // 안전보건목표 마스터 일련번호
              targetName: item.targetName,  // 안전보건목표
              detailName: item.detailName,  // 세부추진사항
              deptName: item.deptName,  // 해당부서
              kpi: item.kpi,  // kpi 성과지표
              performanceGoal: item.performanceGoal,  // 성과목표
              status: null,  // 현황
              achievementRate: 0,  // 달성율
              budget: 0,  // 예산
              performanceDetails: '',  // 실적내용
              remark: '',  // 비고
              month1: 'N',  // 1월 계획
              month2: 'N',  // 2월 계획
              month3: 'N',  // 3월 계획
              month4: 'N',  // 4월 계획
              month5: 'N',  // 5월 계획
              month6: 'N',  // 6월 계획
              month7: 'N',  // 7월 계획
              month8: 'N',  // 8월 계획
              month9: 'N',  // 9월 계획
              month10: 'N',  // 10월 계획
              month11: 'N',  // 11월 계획
              month12: 'N',  // 12월 계획
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C'
            })
          })
        })  
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  }
};
</script>